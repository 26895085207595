import {qspost} from '@/plugins/axios'

/*
 * 管理员列表
 * @constructor jack
 * */
export const adminList = (page, limit, searchName) => qspost('/index/Manage/adminList', {page, limit, searchName})

/*
 * 管理员详情
 * @constructor jack
 * */
export const adminShow = (id) => qspost('/index/Manage/adminShow', {id})

/*
 * 超管 - 添加子管理员
 * @constructor jack
 * */
export const addOperator = (name, password, xingming,phone,email) => qspost('/index/Manage/addOperator', {name, password, xingming,phone,email})

/*
 * 超管 -  修改子管理员
 * @constructor jack
 * */
export const editOperator = (id,name, password, xingming,phone,email) => qspost('/index/Manage/editOperator', {id,name, password, xingming,phone,email})

/*
 * 超管 - 删除子管理员
 * @constructor jack
 * */
export const delOperator = (id) => qspost('/index/Manage/delOperator', {id})

/*
 * 管理员修改自己的密码
 * @constructor jack
 * */
export const selfUpdatePassword = (oldPass, newPass) => qspost('/index/Manage/selfUpdatePassword', {oldPass, newPass})

/*
 * 管理员列表
 * @constructor jack
 * */
export const operatorBindOrg = (adminId, orgStrId) => qspost('/index/Manage/operatorBindOrg', {adminId, orgStrId})

/*
 * 获取可管理的的机构
 * @constructor jack
 * */
export const orgKeGuanLiList = (adminId) => qspost('/index/Manage/orgKeGuanLiList', {adminId})

