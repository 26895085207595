<template>
  <div class="wrapper padding-lr-sm">
    <div class="flex align-center justify-between margin-bottom-sm">
      <div class="flex align-center ">
        <el-input size="small" v-model="keyword" placeholder="请输入管理员姓名/手机号/账号"
                  class=" margin-right-sm search-input"></el-input>
        <el-button size="small" type="primary" @click="hanldSearch">搜索</el-button>
      </div>

      <el-button size="small" type="primary" round @click="subDialog = true">创建管理员</el-button>

    </div>
    <el-table
        :data="subList"
        header-row-class-name="table-header"
        row-class-name="table-row"
        alig="center"
        :cell-style="{'text-align':'center'}"
        border
        style="width: 100%"
    >
      <el-table-column width="70" type="index" align="center" label="序号"></el-table-column>
      <el-table-column label="姓名" prop="xingming"></el-table-column>
      <el-table-column label="账号" prop="name"></el-table-column>
      <el-table-column label="手机号" prop="phone"></el-table-column>
      <el-table-column label="邮箱" prop="email"></el-table-column>
      <el-table-column label="管理公司数量" prop="org_num"></el-table-column>
      <el-table-column width="300" label="操作">
        <template slot-scope="scope">
          <div class="table-operate">
            <div class="flex align-center justify-around margin-bottom-xs">
              <el-button size="small" type="primary" @click="editItem(scope.row)">编辑</el-button>
              <el-button size="small" type="primary" @click="orgItem(scope.row)">公司管理
              </el-button>
              <el-button size="small" type="danger" @click="deleteItem(scope.row.id)">删除</el-button>
            </div>

          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页-->
    <el-pagination
        v-if="subList.length > 0"
        class="page-all"
        background
        @current-change="getList"
        :current-page.sync="page"
        :page-size="limit"
        layout="prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>
    <!--新增修改管理员信息-->
    <el-drawer
        title="管理员信息设置"
        :visible.sync="subDialog"
        :close-on-press-escape="false"
        :before-close="closeDialog"
        :wrapperClosable="false"
    >
      <div class="padding-lr">
        <el-form :model="subForm"
                 label-width="80px"
                 :rules="rules" ref="subForm" class="add-form">
          <el-form-item label="账号" prop="name">
            <el-input v-model="subForm.name" placeholder="请填写账号"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" v-if="!setId">
            <el-input v-model="subForm.password" type="password" placeholder="请填写密码(密码不能小于6位，且不能是纯数字。  )"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="" v-else>
            <el-input v-model="subForm.password" type="password"
                      placeholder="请填写密码(不修改密码无需填写,密码不能小于6位，且不能是纯数字。  )"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="xingming">
            <el-input v-model="subForm.xingming" placeholder="请填写姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="subForm.phone" placeholder="请填写手机号"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="subForm.email" placeholder="请填写邮箱"></el-input>
          </el-form-item>
          <el-form-item label-width="0" class="flex justify-around">
            <el-button class="submit-btn" type="primary" @click="submitForm('subForm')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-drawer>
    <!--管理员管理机构-->
    <el-dialog
        title="机构管理"
        :visible.sync="dialogVisible"

        width="625px"
        :before-close="closeDialog">


      <el-transfer
          :titles="['可选择机构', '已选择机构']"
          v-model="chooseList"
          :props="{key: 'id',label: 'mingcheng'}"
          :data="orgList">
      </el-transfer>


      <div slot="footer" class="flex align-center justify-around">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitOrgEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import rules from "@/utils/rules";
import {addOperator, adminList, delOperator, editOperator, operatorBindOrg, orgKeGuanLiList} from "@/api/admin";

export default {
  name: "subList",
  data() {
    return {
      keyword: '',
      page: 1,
      limit: 10,
      total: 0,
      subList: [],
      subDialog: false,
      subForm: {
        name: '',
        password: '',
        xingming: '',
        phone: '',
        email: '',

      },
      rules: {
        name: rules.mustInput,
        password: rules.mustInput,
        xingming: rules.mustInput,
        phone: rules.phone,
        email: rules.email,
      },
      setId: 0,
      setItem: {},
      dialogVisible: false,
      orgList: [],
      chooseList: [],
    }
  },
  created() {
    this.$emit("setIndex", this.$route.path, "管理员列表", false);
    this.getList()
  },
  components: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      if (this.setId === 0) {
        let _state = await addOperator(
          this.subForm.name,
          this.$md5(this.subForm.password),
          this.subForm.xingming,
          this.subForm.phone,
          this.subForm.email
        )

        if (_state.status === 200) {
          this.cancelDialog()
          this.$message.success(_state.info)
        } else {
          this.$message.error(_state.info)
        }
      } else {
        let _state = await editOperator(
          this.setId,
          this.subForm.name,
          this.subForm.password ? this.$md5(this.subForm.password) : '',
          this.subForm.xingming,
          this.subForm.phone,
          this.subForm.email
        )

        if (_state.status === 200) {
          this.cancelDialog()
          this.$message.success(_state.info)
        } else {
          this.$message.error(_state.info)
        }
      }

    },
    //  获取全部公司列表
    async getOrgList(arr) {
      const res = await orgKeGuanLiList(this.setId)
      res.data.map(item => {
        if (arr.indexOf(item.id) !== -1) {
          this.chooseList.push(Number(item.id))
        }
      })
      console.log(this.chooseList)
      this.orgList = res.data
    },
    //  公司管理
    orgItem(item) {
      this.setId = item.id
      this.setitem = item
      let _arr = item.bind_org.split(',').map(Number)
      this.getOrgList(_arr)
      this.dialogVisible = true
    },
    async submitOrgEdit() {
      console.log(this.chooseList.join(','))
      if (this.chooseList.length === 0) return this.$message.error('请选择要管理的机构')
      await operatorBindOrg(this.setId, this.chooseList.join(','))
      await this.closeDialog()
      await this.getList()
    },
    //  关闭公司管理弹框
    closeDialog() {
      this.setId = 0
      this.chooseList = []
      this.dialogVisible = false
      this.subDialog = false
    },
    //  编辑
    editItem(obj) {
      this.subForm = {
        name: obj.name,
        password: '',
        xingming: obj.xingming,
        phone: obj.phone,
        email: obj.email,
      }
      this.setId = obj.id;
      this.subDialog = true;
    },
    //  员工信息编辑后回调
    cancelDialog() {
      this.setId = 0;
      this.setItem = {};
      this.$refs['subForm'].resetFields();
      this.$refs['subForm'].clearValidate();
      this.subDialog = false;
      this.getList()
    },
    //  删除
    deleteItem(id) {
      this.$confirm('您确定要删除该管理员吗？', "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "msg-btn",
        confirmButtonClass: "msg-btn",
        center: true
      }).then(() => {
        this.sureDelete(id);
      });
    },
    async sureDelete(id) {
      let _state = await delOperator(id);
      if (this.subList.length === 1 && this.page > 1) {
        this.page--;
      }
      this.getList();
      this.$message.success(_state.info);
    },
    //  搜索
    hanldSearch() {
      this.page = 1
      this.getList()
    },
    //  获取管理员列表
    async getList() {
      const res = await adminList(this.page, this.limit, this.keyword)
      this.subList = res.data
      this.total = res.total_number
    }
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.wrapper {
  .search-input {
    width: 300px;
  }
}
</style>
